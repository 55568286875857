import React from "react";

import Layout from "../components/layout";

function isMember(my_list, item) {
    for(var i = 0; i < my_list.length; i++) {
        if (item === my_list[i]) {
            return true;
        }
    }
    return false;
}

const TableRow = ({users, puzzle_entry}) => (
  <tr>
    <td>{puzzle_entry.puzzle}</td>
    {users.map(name => (
        <td>{isMember(puzzle_entry.answers, name) ? "1" : " " }</td>
    ))}
  </tr>
);

const ScoresPage = ({ pageContext }) => (
    <Layout>
    <section>
      <h1>Puzzle Scoreboard</h1>

      <table id="score-table">
        <tbody>
          <tr>
            <th/>
            {pageContext.users.map(name => (
                <th>{name}</th>
            ))}
          </tr>
          
          {pageContext.scores.map(entry => (
              <TableRow users={pageContext.users} puzzle_entry = {entry}/>
          ))}
        </tbody>
      </table>
    </section>
  </Layout>
);

export default ScoresPage;
